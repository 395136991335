<!-- 首页 -->
<template>
  <div>
    <div class="container">
      <!-- 轮播图 -->
      <div class="banner">
        <div class="swiper">
          <div class="swiper-wrapper">
            <div class="swiper-slide one">
              <p v-html="text23"></p>
            </div>
            <div class="swiper-slide two">
              <p>
                {{ text24 }}
                <span v-html="text25"></span>
              </p>
            </div>
          </div>
          <div class="swiper-button-prev"></div>
          <div class="swiper-button-next"></div>
        </div>
        <div class="banner_button">
          <img src="https://jw-ppt.oss-cn-beijing.aliyuncs.com/zl/project/FutureGens/img/index/banner_button.png" alt="" />
          <div>{{ text1 }}</div>
          <ul class="list">
            <li>
              <h3>{{ text2 }}</h3>
              <p>{{ text3 }}</p>
            </li>
            <li>
              <h3>{{ text4 }}</h3>
              <p>{{ text5 }}</p>
            </li>
            <li>
              <h3>{{ text6 }}</h3>
              <p>{{ text7 }}</p>
            </li>
          </ul>
        </div>
      </div>

      <!-- 业务领域 -->
      <div class="field">
        <div class="field_left">
          <div class="left_top">
            <img src="https://jw-ppt.oss-cn-beijing.aliyuncs.com/zl/project/FutureGens/img/index/field_left.png" alt="" />
            <h3>{{ text8 }}</h3>
          </div>
          <div class="left_bottom">
            <p>{{ text9 }}</p>
          </div>
          <router-link class="button" to="/product/pipeline">{{ text11 }}</router-link>
        </div>
        <div class="field_right"></div>
      </div>
      <!-- 平台技术 -->
      <div class="skill">
        <h3>{{ text12 }}</h3>
        <div>
          <img src="https://jw-ppt.oss-cn-beijing.aliyuncs.com/zl/project/FutureGens/img/index/skill.png" alt="" />
          <p class="skill_one">{{ text20 }}</p>
          <p class="skill_two">{{ text21 }}</p>
          <p class="skill_three">{{ text22 }}</p>
        </div>
        <router-link to="/platform/step"
          ><button class="button">{{ text13 }}</button></router-link
        >
      </div>
      <!-- 加入我们 -->
      <div class="join">
        <p class="p">
          {{ text14 }}
        </p>
        <p>{{ text15 }}</p>
        <h3>{{ text16 }}</h3>
        <h4>
          {{ text17 }}
        </h4>
        <router-link to="/contact"
          ><button class="button">{{ text19 }}</button></router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
let language = localStorage.getItem("language") || "cn";
import { mapState } from "vuex";
import Swiper from "swiper";
export default {
  data() {
    switch (language) {
      case "cn":
        return {
          text1: "明济生物概况",
          text2: "2018年",
          text3: "成立于北京",
          text4: "在研产品",
          text5: "10+款",
          text6: "国内布局",
          text7: "北京、上海",
          text8: "治疗领域",
          text9: "深耕于肿瘤及自身免疫疾病等领域，专注于生物创新药的开发和产业化",
          text11: "查看更多",
          text12: "创新平台技术",
          text13: "查看更多",
          text14: "在公司使命和愿景下，明济生物已汇聚了一群优秀的生物医药专家",
          text15: "拥有领先的药物研发、临床、CMC、质量等方面的丰富行业经验",
          text16: "加入我们",
          text17:
            "我们是一家充满活力的企业，拥有科学驱动、勇于突破、积极沟通、目标明确的团队，我们也诚心邀请各路英才，齐聚一堂，研究开发出能够真正满足临床需求及解决病人痛苦的药物，为人类健康长寿迈开新的篇章。",
          text19: "查看更多",
          text20: "颠覆性的蛋白药物筛选优化STEP平台",
          text21: "全面的免疫生物功能分析平台",
          text22: "高效的CMC生产平台",
          text23: "致力于开发创新生物药<br/>造福全人类健康",
          text24: "专注于肿瘤免疫领域",
          text25: "通过科学与技术的持续创新，成长为全球领先的生物制药公司",
        };
        break;
      case "en":
        return {
          text1: "About Us",
          text2: "Establishment",
          text3: "2018",
          text4: "Products in R&D",
          text5: "10+",
          text6: "HQ & Branch",
          text7: "Beijing, Shanghai",
          text8: "Therapeutic Areas",
          text9: "FutureGen is intensively engaged in oncology, and immunology areas focusing on the development and industrialization of innovative biologics.",
          text11: "More",
          text12: "Innovative Platform Technology",
          text13: "More",
          text14: "FutureGen′s culture is clear with vision and mission, and the professional team of biomedical experts has been established. ",
          text15: "We have rich industrial experience in drug development, clinical research, CMC, and quality.",
          text16: "Join us",
          text17:
            "We are a science-driven company with energetic team of enthusiasm and ambitious goals to achieve breakthrough. We sincerely invite talents to join us to develop innovative biologics that can truly meet clinical needs to relieve patient suffering, and to improve human health and longevity.",
          text19: "More",
          text20: "STEP：Structure-based Targeted Evolution Platform",
          text21: "Powerful Immunological Function Analysis Platform",
          text22: "High-efficiency CMC Production Platform",
          text23: "FutureGen is committed to developing innovative biologics to benefit human health.",
          text24: "Focus on oncology and immunology areas",
          text25: "To become a globally leading biopharmaceutical company through continuous innovation in science and technology.",
        };
        break;
    }
  },
  computed: {
    ...mapState(["isLanguage"]),
  },
  mounted() {
    var mySwiper = new Swiper(".swiper", {
      loop: true, // 循环模式选项
      autoplay: {
        delay: 4000,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    });
    mySwiper.el.onmouseover = function () {
      mySwiper.autoplay.stop();
    };

    //鼠标离开开始自动切换
    mySwiper.el.onmouseout = function () {
      mySwiper.autoplay.start();
    };
  },
};
</script>
<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
}
body {
  overflow-x: hidden;
  font-family: Arial, Helvetica, sans-serif, "microsoft yahei", "pingfang sc";
  background: white;
}
a {
  -webkit-tap-highlight-color: transparent;
}
.button {
  position: relative;
  width: max-content;
  text-align: center;
  font-size: 24px;
  cursor: pointer;
  border: 0;
  margin: 5vh auto;
  outline: none;
  color: #203864;
  -webkit-tap-highlight-color: transparent;
  padding: 1em 2.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f3f5f8;
}
.button::after {
  content: "";
  width: 30px;
  height: 30px;
  background: url("https://jw-ppt.oss-cn-beijing.aliyuncs.com/zl/project/FutureGens/img/index/but.png");
  display: block;
  margin-left: 1em;
}
.container {
  width: 100vw;
  overflow: hidden;
  .banner {
    background: #203864;
    .swiper {
      height: 960px;
      position: relative;
      .swiper-wrapper {
        .one {
          background: url("https://jw-ppt.oss-cn-beijing.aliyuncs.com/zl/project/FutureGens/img/index/banner.jpg");
          background-position: center;
          background-size: cover;
          p {
            font-size: 38px;
            color: #fff;
            text-align: left;
            font-weight: normal;
            position: absolute;
            top: 45%;
            left: 50%;
            transform: translate(-95%, -100%);
            width: 40%;
            line-height: 1.6;
          }
        }
        .two {
          position: relative;
          background: url("https://jw-ppt.oss-cn-beijing.aliyuncs.com/zl/project/FutureGens/img/index/banner2.png");
          background-position: center;
          background-size: cover;
          text-align: center;
          color: #fff;
          p {
            position: absolute;
            font-size: 50px;
            font-weight: normal;
            top: 50%;
            left: 50%;
            width: 80%;
            transform: translate(-50%, -75%);
          }
          span {
            font-size: 35px;
            color: rgba(255, 255, 255, 0.9);
            font-weight: normal;
            display: block;
            position: relative;
            margin: 2vh auto 0 auto;
            width: 80%;
          }
          span::before {
            content: "";
            position: absolute;
            top: -10px;
            left: 50%;
            transform: translateX(-50%);
            width: 50px;
            height: 2px;
            background: #ccc;
          }
        }
      }
      .swiper-button-prev {
        width: 70px;
        height: 70px;
        margin-left: 5%;
        color: #fff;
        border-radius: 50%;
        top: 50%;
        transform: translateY(-100%) scale(0.6);
        background: rgba(0, 0, 0, 0.4);
      }
      .swiper-button-next {
        width: 70px;
        height: 70px;
        margin-right: 5%;
        color: #fff;
        border-radius: 50%;
        top: 50%;
        transform: translateY(-100%) scale(0.6);
        background: rgba(0, 0, 0, 0.4);
      }
    }
    .banner_button {
      width: 100%;
      max-width: 1200px;
      height: 223px;
      margin: 0 auto;
      color: rgba(255, 255, 255, 0.9);
      display: flex;
      justify-content: space-between;
      align-items: center;
      img {
        position: absolute;
        z-index: 1;
      }
      div {
        font-size: 36px;
        position: relative;
        z-index: 2;
        margin-left: 100px;
      }
      .list {
        list-style: none;
        display: flex;
        li {
          position: relative;
          width: 215px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          text-align: center;
          padding: 0 5px;
          h3 {
            font-size: 25px;
          }
          p {
            font-size: 24px;
            color: rgba(255, 255, 255, 0.8);
          }
        }
        li::after {
          content: "";
          position: absolute;
          top: 48%;
          right: 0;
          width: 1px;
          height: 40px;
          background: #999999;
        }
      }
    }
  }
  .field {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .field_left {
      width: 48%;
      text-align: right;
      padding: 5vh 0;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      .button {
        margin: 5vh 0;
      }
      .left_top {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        h3 {
          position: relative;
          font-size: 50px;
          color: #203864;
          margin-bottom: 30px;
        }
        h3::after {
          position: absolute;
          top: 130%;
          left: 68%;
          width: 50px;
          height: 2px;
          content: "";
          background-color: #ccc;
        }
      }
      .left_bottom {
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        p {
          color: #999999;
          font-size: 18px;
          line-height: 1.8;
          width: 80%;
        }
      }
    }
    .field_right {
      width: 48%;
      background: url("https://jw-ppt.oss-cn-beijing.aliyuncs.com/zl/project/FutureGens/img/index/field_right.png") no-repeat;
      background-size: cover;
      height: 700px;
    }
  }
  .skill {
    width: 100%;
    background: #f3f5f8;
    text-align: center;
    padding: 5vh 0;
    h3 {
      position: relative;
      font-size: 50px;
      color: #203864;
      font-weight: normal;
    }
    h3::after {
      content: "";
      position: absolute;
      bottom: -30%;
      left: 50%;
      width: 50px;
      height: 2px;
      background-color: #ccc;
      transform: translateX(-50%);
    }
    div {
      position: relative;
      width: 100%;
      max-width: 1200px;
      height: 250px;
      margin: 0px auto;
      padding-top: 8vh;
      text-align: center;
      p {
        color: #4a4a4a;
        font-size: 25px;
        line-height: 1.4;
      }
      .skill_one {
        position: absolute;
        top: 100%;
        left: 0%;
        width: 30%;
      }
      .skill_two {
        position: absolute;
        top: 60%;
        left: 35%;
        width: 30%;
      }
      .skill_three {
        position: absolute;
        top: 100%;
        right: 0%;
        width: 30%;
      }
    }
    button {
      margin-top: 9%;
      background-color: #20386421;
    }
  }
  .join {
    height: 754px;
    background: url("https://jw-ppt.oss-cn-beijing.aliyuncs.com/zl/project/FutureGens/img/index/join.png") no-repeat;
    background-size: cover;
    text-align: center;
    color: #fff;
    .p {
      padding-top: 120px;
    }
    p {
      font-size: 18px;
      color: #f3f5f8;
      max-width: 1200px;
      margin: 0 auto;
      line-height: 1.6;
    }
    h3 {
      position: relative;
      font-size: 50px;
      margin: 70px auto 50px auto;
      font-weight: normal;
    }
    h3::after {
      content: "";
      position: absolute;
      bottom: -30%;
      left: 50%;
      width: 50px;
      height: 2px;
      background-color: #ccc;
      transform: translateX(-50%);
    }
    h4 {
      font-weight: normal;
      font-size: 16px;
      line-height: 1.8;
      opacity: 0.8;
      width: 42vw;
      margin: 0 auto;
    }
    button {
      color: #fff;
      border: 1px solid #fff;
      background-color: transparent;
    }
    button::after {
      background: url("https://jw-ppt.oss-cn-beijing.aliyuncs.com/zl/project/FutureGens/img/index/but_white.png");
    }
  }
}

@media screen and (max-width: 750px) {
  .container {
    width: 100vw;
    .banner {
      .swiper {
        width: 100%;
        height: 40vh;
        -webkit-tap-highlight-color: transparent;
        .swiper-wrapper {
          .one {
            p {
              font-size: 20px;
              transform: translate(-50%, -50%);
              width: 90%;
              background: rgba(0, 0, 0, 0.3);
              padding: 20px;
              box-sizing: border-box;
            }
          }
          .two {
            p {
              font-size: 20px;
              transform: translate(-50%, -50%);
              width: 90%;
            }
            span::after {
              display: none;
            }
            span {
              font-size: 15px;
              width: 70%;
            }
          }
        }
        .swiper-button-prev {
          display: none;
        }
        .swiper-button-next {
          display: none;
        }
      }
      .banner_button {
        display: none;
      }
    }
    .button {
      font-size: 16px;
      padding: 0.5em 2em;
    }
    .field {
      justify-content: center;
      .field_left {
        width: 90vw;
        text-align: center;
        .left_top {
          margin-top: 30px;
          h3 {
            width: 100%;
            font-size: 30px;
            margin-bottom: 0px;
            font-weight: normal;
          }
          img {
            display: none;
          }
          .left_bottom {
            margin-top: 30px;
            p {
              color: #999999;
              font-size: 18px;
              line-height: 36px;
            }
          }
        }
      }
      .field_right {
        display: none;
      }
    }

    .skill {
      height: 445px;
      img {
        width: 83vw;
        height: 23vw;
      }
      h3 {
        font-size: 30px;
      }
      div {
        width: 90vw;
        p {
          font-size: 14px;
        }
        .skill_one {
          top: 57%;
          left: -3%;
          width: 33%;
        }
        .skill_two {
          top: 40%;
          left: 34%;
          width: 33%;
        }
        .skill_three {
          top: 57%;
          right: -6%;
          width: 33%;
        }
      }
      button {
        margin-top: -15%;
      }
    }
    .join {
      position: relative;
      height: 400px;
      padding: 20px;
      background-size: cover;
      h3 {
        font-size: 30px;
        margin: 20px auto;
      }
      h4 {
        text-align: left;
        margin-top: 35px;
        width: 90vw;
      }
      p {
        display: none;
      }
    }
  }
}
</style>
